import {
  ButtonGroup,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

export const ProjectPreview = ({
  title,
  gif,
  imgSrc,
  description,
  subtitle,
  cta,
  onClose,
  videoUrl,
}) => {
  const isMobileScreen = useBreakpointValue({ base: true, md: false });
  const isSmallScreen = useBreakpointValue({ base: true, md: true, lg: false });

  return (
    <Modal
      isOpen
      onClose={onClose}
      size={isMobileScreen ? "full" : isSmallScreen ? "2xl" : "3xl"}
      scrollBehavior="outside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody bgColor="var(--card-bg)" borderRadius="none">
          <ModalCloseButton size="lg" />
          <VStack p={8} px={isMobileScreen ? 1 : 8} h="full">
            <VStack gap={isMobileScreen ? 3 : 6} h="full">
              <Heading as="h1">{title}</Heading>
              {videoUrl ? (
                <video
                  w="100%"
                  height="auto"
                  autoPlay="1"
                  loop="1"
                  controls
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                  poster={imgSrc}
                >
                  <source src={videoUrl} type="video/mp4" alt="project" />
                </video>
              ) : (
                <Image
                  src={gif || imgSrc}
                  alt={title}
                  w="full"
                  h="auto"
                  border={gif ? "1px solid" : "none"}
                  borderColor="#c8c8c8"
                />
              )}
              <Flex direction="column" align="center" textAlign="center">
                <Text
                  color="var(--title-color)"
                  fontWeight="bold"
                  fontSize="xl"
                >
                  {subtitle}
                </Text>
                <Text fontSize="md" color="var(--subtitle-color)">
                  {description}
                </Text>
              </Flex>
            </VStack>
            <ButtonGroup w="full">{cta}</ButtonGroup>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
