export const simplifiedWorkList = [
  {
    title: "React.js, Redux, Framer Motion and ChakraUI",
    childrens: [
      {
        title:
          "Led developing tools like Text-to-Image generator, AI Chatbot, CMD+K command tool, and Presentation Generator.",
        children: [
          {
            title: "Text-to-Image generator",
            url: "https://app.simplified.com/graphic-design/tools/text-to-image",
          },
          {
            title: "AI Chatbot",
            url: "https://app.simplified.com/chatbot",
          },
          {
            title: "CMD+K Global search",
            url: "https://saurabhchirde.com/?project=65135c86-0d78-23a9-a1c7-d8bee8f8fc7c",
          },
          {
            title: "Presentation Generator.",
            url: "https://app.simplified.com/design/generate-ai/presentation",
          },
        ],
      },
      "Redesigned the user experience using Figma's updated layouts and streamlined the older code with modular design and best coding practices to optimize performance, which made the app run 20-30% faster and smoother.",
      "Added loading skeletons and migrated class-based components to functional components.",
      "Implemented Framer Motion for subtle animations to enhance UI interactions.",
      "Deep linking from landing pages to the application.",
      "Reviewed peers pull requests to improve the code quality and collaborated with cross functional teams on Gitlab and Sentry.",
    ],
  },
  {
    title: "Next.js (SSR)",
    childrens: [
      "Built SEO-optimized pages for designs and videos templates.",
      "Implemented best SEO practices for 15+ AI Growth tools, resulting in top 3 google searches",
      "Developed user community profiles and contributed to growth tools for images and videos.",
    ],
  },
  {
    title: "React Native with Expo",
    childrens: [
      {
        title:
          "Led the development & maintenance of Unleash AI mobile app (150,000+ downloads on iOS & Android).",
        children: [
          {
            title: "iOS App URL",
            url: "https://apps.apple.com/in/app/unleash-ai/id6479893586",
          },
          {
            title: "Android App URL",
            url: "https://play.google.com/store/apps/details?id=com.simplified.aiphotos",
          },
        ],
      },
      "Managed other mobile apps, fixed user-facing issues, and deployed updates.",
      "Built and submitted apps to Google Play Store & Apple App Store.",
    ],
  },
];

export const novelValvesWorkList = [
  {
    title: "Software Developer (March 2020 - Jan 2022)",
    childrens: [
      "Built the v0 version of a work management system to streamline team workflows, enhance collaboration and improve resource tracking for better efficiency",
      "Maintained internal systems, managed and optimized website SEO, resulting in ranking on Google's first page ",
      "Mentored interns and conducted regular peer code reviews to enhance team code quality and best practices.",
    ],
  },
  {
    title: "IT Engineer (March 2018 - March 2020)",
    childrens: [
      "Maintained internal systems, including Outlook management, storage cleanup, and system optimization.",
      "Built and managed the company website using WordPress themes, ensuring smooth functionality and SEO optimization",
      "Provided technical support for internal teams, troubleshooting system issues and improving workflow efficiency",
      "Assisted the sales team with quotations during spare time, streamlining the process and improving response times",
    ],
  },
];
