import {
  birthday,
  cashReg,
  diceGame,
  lucky,
  profitLoss,
  tossDice,
  weather,
  triangle,
} from "./Images/ScreenShots";

export const otherProjects = [
  {
    id: "87455c86-0d78-42a9-a1c7-d5bee8f8fc3c",
    wrapperClass: "with-live other-projects",
    title: "Weather Forcast",
    imgSrc: weather,
    description:
      " A web app to show the recent weather and three days forecast of a city by providing the city name.",
    primaryLink: "https://weather.saurabhchirde.com/",
    secondaryLink: "https://github.com/saurabhchirde/weather-forcast",
    skeletonWidth: "21rem",
  },
  {
    id: "65685c86-0d78-42a9-a1c7-36abe8f8fc3c",
    wrapperClass: "with-live other-projects",
    title: "Dice Game",
    imgSrc: diceGame,
    description:
      " A fun two-player dice game. The player to score 100 first, wins the game!",
    primaryLink: "https://distracted-poincare-6f7cf0.netlify.app",
    secondaryLink:
      "https://github.com/saurabhchirde/Who-scores-100-first-Dice-game",
    skeletonWidth: "21rem",
  },
  {
    id: "65685c86-0d78-42a9-8524-d5bee8f8fc3c",
    wrapperClass: "with-live other-projects",
    title: "Toss The Dice",
    imgSrc: tossDice,
    description:
      "A web app to replace the old style of tossing the coin to find the winner.",
    primaryLink: "https://trusting-tesla-5b45ae.netlify.app",
    secondaryLink: "https://github.com/saurabhchirde/Toss-the-dice",
    skeletonWidth: "21rem",
  },
  // {
  //   wrapperClass: "with-live other-projects",
  //   title: "Stock, Profit or Loss",
  //   imgSrc: profitLoss,
  //   description:
  //     "Let's find whether you made a profit or a loss in your trade!",
  //   primaryLink: "https://sleepy-heyrovsky-1dbb7d.netlify.app",
  //   secondaryLink: "https://github.com/saurabhchirde/profit-or-loss",
  //   skeletonWidth: "21rem",
  // },
  // {
  //   wrapperClass: "with-live other-projects",
  //   title: " Is Your Birthdate a Palindrome ?",
  //   imgSrc: birthday,
  //   description:
  //     "let's check whether your birthday is a palindrome or not. If not, then find the nearest palindrome date!",
  //   primaryLink: "https://pensive-golick-6a6c45.netlify.app",
  //   secondaryLink: "https://github.com/saurabhchirde/palindrome-birthday",
  //   skeletonWidth: "21rem",
  // },
  // {
  //   wrapperClass: "with-live other-projects",
  //   title: "Fun With Triangles",
  //   imgSrc: triangle,
  //   description:
  //     " A fun application, to test your knowlege about different types of triangles and their angles.",
  //   primaryLink: "https://condescending-wright-64dd03.netlify.app",
  //   secondaryLink: "https://github.com/saurabhchirde/fun-with-triangle",
  //   skeletonWidth: "21rem",
  // },
  // {
  //   wrapperClass: "with-live other-projects",
  //   title: "Is Your Birthday Lucky ?",
  //   imgSrc: lucky,
  //   description:
  //     "An application that takes the user's birthdate and their lucky number to find if their birthday is lucky or not!",
  //   primaryLink: "https://angry-lewin-c70e57.netlify.app",
  //   secondaryLink: "https://github.com/saurabhchirde/Is-your-birthday-lucky",
  //   skeletonWidth: "21rem",
  // },
  // {
  //   wrapperClass: "with-live other-projects",
  //   title: "Cash Register",
  //   imgSrc: cashReg,
  //   description:
  //     "A web app to let users, find the exact number of notes to return to the customer by entering the bill amount and the given cash.",
  //   primaryLink: "https://hardcore-chandrasekhar-cbb371.netlify.app",
  //   secondaryLink: "https://github.com/saurabhchirde/cash-register",
  //   skeletonWidth: "21rem",
  // },
];
