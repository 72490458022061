import { Flex, Text } from "@chakra-ui/react";

export const CollectionWrapper = ({ children, title, ...rest }) => {
  return (
    <Flex
      w="full"
      wrap="wrap"
      justifyContent="center"
      alignItems="center"
      className="project-section main-projects"
      gap={["0.5rem", "0.5rem", "1rem", "2rem"]}
      px={["2%", "2%", "5%", "4%"]}
      py="2%"
      direction="column"
      maxW="1240px"
      {...rest}
    >
      {title && (
        <Flex className="section-header" direction="column" alignItems="center">
          <Text
            as="h2"
            className="section-title"
            fontSize={["lg", "lg", "2xl"]}
          >
            {title}
          </Text>
          <hr />
        </Flex>
      )}
      {children}
    </Flex>
  );
};
