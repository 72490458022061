import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const colors = {
  dark: {
    bg_color: "#141517",
    title_color: "rgb(241, 241, 241)",
    primary_color: "#e8e8e8",
    btn_hover: "rgba(242, 242, 242, 0.148)",
    card_bg: "#1b1c1e",
    card_border: "rgb(79, 79, 79)",
  },
  light: {
    bg_color: "#f8f8f8",
    title_color: "rgb(34, 34, 34)",
    primary_color: "#16284f",
    btn_hover: "rgba(29, 29, 29, 0.146)",
    card_bg: "white",
    card_border: "rgb(210, 210, 210)",
  },
};

const styles = {
  global: (props) => ({
    body: {
      bg: mode("#f8f8f8", "#141517")(props),
      overflow: "auto", //needed for full screen mode
    },
  }),
};

const theme = extendTheme({ config, colors, styles });

export default theme;
