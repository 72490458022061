import { Link, useLocation } from "react-router-dom";
import { ThemeToggle } from "Components";
import icon from "Data/Logo/icon.svg";
import "./NavBar.css";
import {
  IconButton,
  Flex,
  useBreakpointValue,
  useColorMode,
  Link as ChakraLink,
  Tooltip,
} from "@chakra-ui/react";
import { HOME_PATH, PHOTOS_PATH } from "endpoints";
import { FaBlogger, FaDownload, FaHome, FaCamera } from "react-icons/fa";

export const NavBar = () => {
  const { pathname } = useLocation();
  const { colorMode } = useColorMode();
  const activeHome = pathname === HOME_PATH;
  const activePhotos = pathname === PHOTOS_PATH;

  const isMobileScreen = useBreakpointValue({
    base: true,
    md: true,
    lg: false,
  });

  const iconButtonBgColor = "var(--primary-color)";

  return (
    <nav
      style={{
        backgroundColor:
          colorMode === "dark"
            ? "rgba(104, 101, 101, 0.15)"
            : "rgba(155, 155, 155, 0.1)",
      }}
    >
      <Link to={HOME_PATH}>
        <img src={icon} alt="icon" className="logo-icon" />
      </Link>
      <Flex className="menu" gap={isMobileScreen ? 2 : 5} align="center">
        <Tooltip
          label={activeHome ? "Home" : ""}
          hasArrow
          placement={isMobileScreen ? "top" : "left"}
        >
          <Link className={activeHome ? "active-nav" : ""} to={HOME_PATH}>
            <IconButton
              variant="secondary"
              fontSize="xl"
              icon={<FaHome />}
              bgColor={activeHome ? iconButtonBgColor : ""}
              color={activeHome ? "white" : "unset"}
              isRound
            />
          </Link>
        </Tooltip>
        <Tooltip
          label={!activePhotos ? "Photo Gallery" : ""}
          hasArrow
          placement={isMobileScreen ? "top" : "left"}
        >
          <Link className={activePhotos ? "active-nav" : ""} to={PHOTOS_PATH}>
            <IconButton
              variant="secondary"
              fontSize="xl"
              icon={<FaCamera />}
              bgColor={activePhotos ? iconButtonBgColor : ""}
              color={activePhotos ? "white" : "unset"}
              isRound
            />
          </Link>
        </Tooltip>
        <Tooltip
          label="Download Resume"
          hasArrow
          placement={isMobileScreen ? "top" : "left"}
        >
          <ChakraLink
            isExternal
            href="https://drive.google.com/file/d/1mU_99PB8xVPlm1-pDGl3GBO8AXcSeOFt/view?usp=sharing"
          >
            <IconButton
              variant="secondary"
              fontSize="xl"
              icon={<FaDownload />}
              isRound
            />
          </ChakraLink>
        </Tooltip>
        <Tooltip
          label="Blogs"
          hasArrow
          placement={isMobileScreen ? "top" : "left"}
        >
          <ChakraLink isExternal href="https://saurabhchirde.hashnode.dev/">
            <IconButton
              variant="secondary"
              fontSize="xl"
              icon={<FaBlogger />}
              isRound
            />
          </ChakraLink>
        </Tooltip>
        <ThemeToggle />
      </Flex>
    </nav>
  );
};
