import {
  Heading,
  Flex,
  Text,
  Link,
  HStack,
  Icon,
  useColorMode,
} from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";

export const WorkExpList = ({ list, title }) => {
  const { colorMode } = useColorMode();

  return (
    <Flex direction="column" gap={3} w="full">
      <Heading fontSize={["lg", "lg", "lg", "xl"]}>{title}</Heading>
      <Flex direction="column" pl={4} gap={1}>
        {list.map((item, pos) =>
          item?.children ? (
            <Flex direction="column" gap={1} w="full" mb={2} key={pos}>
              <Text fontSize="lg">{`${pos + 1} .  ${item.title}`}</Text>
              <Flex direction="column" pl={6} gap={1}>
                {item.children.map((childItem, childPos) => (
                  <HStack align="center" gap={2} key={childPos}>
                    <Text
                      as={Link}
                      href={childItem.url}
                      target="_blank"
                      fontSize="lg"
                    >
                      {`- ${childItem.title}`}
                      <Icon
                        ml={4}
                        as={FaExternalLinkAlt}
                        color={colorMode === "dark" ? "yellow" : "blue"}
                      />
                    </Text>
                  </HStack>
                ))}
              </Flex>
            </Flex>
          ) : (
            <Text key={pos} fontSize="lg">{`${pos + 1} .  ${item}`}</Text>
          )
        )}
      </Flex>
    </Flex>
  );
};
