import {
  ae,
  ai,
  chakra,
  figma,
  framer,
  git,
  html,
  js,
  next,
  ps,
  react,
  ts,
  vs,
  xd,
  css,
} from "./ToolsLogo";

export const SKILLS = [
  { title: "ReactJS", src: react, url: "https://react.dev/" },
  { title: "NextJS", src: next, url: "https://nextjs.org/" },
  { title: "TypeScript", src: ts, url: "https://www.typescriptlang.org/" },
  {
    title: "JavaScript",
    src: js,
    url: "https://www.w3schools.com/js/default.asp",
  },
  { title: "HTML", src: html, url: "https://www.w3schools.com/html/" },
  { title: "CSS", src: css, url: "https://www.w3schools.com/css/default.asp" },
  { title: "Git", src: git, url: "https://git-scm.com/" },
  { title: "Figma", src: figma, url: "https://www.figma.com/" },
  { title: "XD", src: xd, url: "https://adobexdplatform.com/" },
  { title: "ChakraUI", src: chakra, url: "https://chakra-ui.com/" },
  { title: "Framer", src: framer, url: "https://framermotion.framer.website/" },
  {
    title: "Photoshop",
    src: ps,
    url: "https://www.adobe.com/in/products/photoshop.html",
  },
  {
    title: "Illustrator",
    src: ai,
    url: "https://www.adobe.com/in/products/illustrator.html",
  },
  {
    title: "AfterEffect",
    src: ae,
    url: "https://www.adobe.com/in/products/aftereffects.html",
  },
  { title: "VS Code", src: vs, url: "https://code.visualstudio.com/docs" },
];
